<!-- tablePage -->
<template>
  <div class="section-nopad">
    <Section :title="module.title" :hasFooter="false">
      <el-table
        ref="dragTable"
        :data="tableData"
        :show-header="false"
        class="list-table"
        :row-style="{ height: '64px' }"
        v-loading="loading"
      >
        <el-table-column prop="value" label="" style="width: 100%">
          <template slot-scope="scope">
            <div class="rowName">
              <img
                :src="require('@/assets/images/my-handle.svg')"
                style="display: none"
                class="my-handle isDisplay"
              />
              <div class="editName" v-if="scope.row.isEdit">
                <gg-input
                  key="date"
                  ref="date"
                  placeholder=""
                  @keyup="keyup(scope.$index, scope.row)"
                  v-model="scope.row.value"
                  :autofocus="true"
                  :error="errorObj"
                ></gg-input>
                <div class="operation">
                  <span
                    class="iconfont icontick"
                    :class="{
                      'not-allowed':
                        !scope.row.isAllowed || scope.row.value == '',
                    }"
                    @click="handleOperator(scope.$index, scope.row)"
                  ></span>
                  <span
                    class="iconfont iconclear-px"
                    @click="handleClose(scope.$index, scope.row)"
                  ></span>
                </div>
              </div>
              <div class="showName" v-if="!scope.row.isEdit">
                {{ scope.row.value }}
              </div>
            </div>
            <div
              class="action isDisplay"
              style="display: none"
              v-if="!scope.row.isEdit"
            >
              <BaseButton
                type="primary"
                @click="handleEdit(scope.$index, scope.row)"
                >Edit</BaseButton
              >
              <BaseButton @click="handleDelete(scope.$index, scope.row)"
                >Delete</BaseButton
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="wraper" v-if="disabledAddoption">
        <div class="append">
          <span class="text" @click="handleAddOption()">Add option</span>
        </div>
      </div>
    </Section>
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
    <!-- "Class has been moved to closed" -->
  </div>
</template>
        

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import Sortable from "sortablejs";
export default {
  data() {
    return {
      loading: false,
      module: {
        title: "",
        moduleType: "",
        moduleName: "",
      },
      disabledAddoption: true,
      tableData: [],
      // isAllowed: true,
      oldVal: "",
      snackbar: { isShow: false, content: "" },
      ids: [], // 拖动排序
      errorObj: {
        show: false,
        message: "",
      },
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
  },
  methods: {
    _getData() {
      var data = {
        // type: this.user.userType,
        userId: this.user.userId,
        dataLevel: this.module.moduleName,
        dataType: this.module.moduleType,
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/getList", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this.tableData = response || [];
          this.disabledAddoption = true;
          this.errorObj = {
            show: false,
            message: "",
          };
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateSetting() {
      var data = {
        ids: this.ids || [],
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/updateSetting", data)
        .then((res) => {
          this._getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleEdit(index, row) {
      this.$set(row, "isEdit", true);
      this.oldVal = row.value;
    },
    handleDelete(index, row) {
      let messageObj = {
        title: "Delete option?",
        desc: "Once deleted, the option will not be recovered.",
        cancelTxt: "Cancel",
        confirmTxt: "Delete",
      };
      let callback = () => {
        this.actualDelete(index, row);
      };
      this.openMessageBox(messageObj, callback);
    },
    actualDelete(index, row) {
      let data = {
        id: row.id,
      };
      Ajax.get("/usermanage/setting/deleteById", data).then((res) => {
        let data = res.data;
        this.handleSuspend(res.message);
        this.$set(row, "isEdit", false);
        this.disabledAddoption = true;
        this._getData();
      });
    },
    handleOperator(index, row) {
      if (!row.isAllowed) return;
      if (!this.disabledAddoption) {
        this.handleAdd(index, row);
      } else {
        this.handleModify(index, row);
      }
    },
    handleAdd(index, row) {
      var data = {
        userId: this.user.userId,
        dataLevel: this.module.moduleName,
        dataType: this.module.moduleType,
        type: this.user.userType,
        value: row.value,
      };
      Ajax.post("/usermanage/setting/save", data).then((res) => {
        if (res.code == "0006") {
          this.errorObj = {
            show: true,
            message: "The option already exists",
          };
          this.$set(this.tableData[index], "isAllowed", false);
        } else {
          this.handleSuspend(res.message);
          this.disabledAddoption = true;
          this.$set(row, "isEdit", false);
          this._getData();
        }
      });
    },

    handleModify(index, row) {
      var data = {
        userId: this.user.userId,
        id: row.id,
        dataLevel: this.module.moduleName,
        dataType: this.module.moduleType,
        type: this.$store.state.user.userType,
        value: row.value,
      };
      Ajax.post("/usermanage/setting/update", data).then((res) => {
        if (res.code == "0006") {
          this.errorObj = {
            show: true,
            message: "The option already exists",
          };
          this.$set(this.tableData[index], "isAllowed", false);
        } else {
          this.$set(row, "isEdit", false);
          this.disabledAddoption = true;
          this._getData();
        }
      });
    },
    handleClose(index, row) {
      this.$set(row, "isEdit", false);
      this.$set(row, "value", this.oldVal);
      this.$set(this.tableData[index], "isAllowed", false);
      // add option
      if (!this.disabledAddoption) {
        this.disabledAddoption = true;
        this.tableData.splice(index, 1);
      }
    },
    handleAddOption() {
      this.tableData.push({ value: "" });
      this.$set(this.tableData[this.tableData.length - 1], "isEdit", true);
      this.disabledAddoption = false;
    },
    keyup(index, row) {
      if (this.oldval !== row.value) {
        this.$set(this.tableData[index], "isAllowed", true);
      } else {
        this.$set(this.tableData[index], "isAllowed", false);
      }
    },
    //行拖拽
    rowDrop() {
      const tbody = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper tbody"
      )[0];
      const _this = this;
      this.sortable = Sortable.create(tbody, {
        handle: ".my-handle",
        draggable: "tr", // 指定那些选项需要排序
        setData: function (dataTransfer) {
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          dataTransfer.setData("value", "");
        },
        onEnd({ newIndex, oldIndex }) {
          let list = _this.tableData;
          const targetRow = list.splice(oldIndex, 1)[0];
          list.splice(newIndex, 0, targetRow);
          _this.tableData = []; // https://blog.csdn.net/weixin_33712881/article/details/91477794
          _this.$nextTick(() => {
            _this.tableData = [...list];
            _this.tableData.map((item) => _this.ids.push(item.id));
            _this.updateSetting();
            console.log(_this.tableData);
          });
        },
      });
    },
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        showClose:false,
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      });
      //  .then(() => {
      //   this.handleSuspend("dddd");
      // });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
  },
  created() {
    this.module = { ...this.$route.query };
    this._getData();
  },
  mounted() {
    this.rowDrop();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.el-table {
  /deep/tbody tr:hover {
    .isDisplay {
      display: inherit !important;
    }
  }
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #eee;
}
/deep/.section-main {
  margin: 0;
  max-height: calc(100vh - 112px);
  overflow-y: auto;
}
/deep/.el-table td {
  padding: 3px 0;
}
.list-table {
  .not-allowed {
    cursor: not-allowed !important;
    color: #cbcbcb !important;
  }
  .iconfont {
    font-size: 24px;
    line-height: 24px;
    margin-left: 16px;
    cursor: pointer;
  }
  .rowName {
    position: relative;
    padding-left: 40px;
    font-family: Roboto;
    font-size: 16px;
    color: #202124;
    .my-handle {
      position: absolute;
      cursor: grab;
      left: 0;
      transform: translateY(-50%);
      top: 50%;
    }

    .editName {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .showName {
    }
  }
  .cell {
    .action {
      position: absolute;
      top: 12px;
      right: 0;
    }
  }
  // .editName {
  //   position: relative;

  //   .my-handle {
  //     position: absolute;
  //     cursor: grab;
  //     left: 0;
  //     top: 0;
  //   }
  // }

  .el-table .cell {
    overflow: inherit;
  }
  .md-field {
    margin-bottom: 0;
    padding-top: 0;
    min-height: 32px;
  }
  .md-field.md-invalid {
    margin-bottom: 20px;
  }
  .md-field .md-error {
    bottom: -13px;
  }
}

.wraper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 57px;
  line-height: 57px;
  font-size: 14px;
  color: #202124;
  font-family: Roboto;
  padding-left: 50px;
  .append {
    font-family: Roboto;
    font-size: 14px;
    color: #1a73e8;
    cursor: pointer;
  }
}
/deep/ .el-table__empty-block{
	display: none;
}
</style>